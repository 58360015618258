import React from "react"

const Header = () => {
    return <div className='header'>

        <img className='logo' src='/static/logo.png'></img>

    </div>
}

export default Header;



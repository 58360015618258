import React, { useEffect, useState } from "react";
import { useLoaderData } from "react-router-dom";
import parse from 'html-react-parser'

const ContentWrapper = ({ url, ...props }) => {

    const [content, setContent] = useState('');

    const loader = useLoaderData();

    const changeTarget = (content) => {
        content.map((obj) => {
            obj.setAttribute('target', 'blank');
        })
    }

    const changeTable = (content) => {
        content.map((obj) => {
            obj.setAttribute('border', '0');
            obj.setAttribute('cellpadding', '0');
            obj.setAttribute('cellspacing', '0');
        })
    }

    useEffect(() => {
        loader.request
            .then(data => setContent(data[loader.id].fields.content))

        var allLinks = [...document.getElementsByTagName('a')];
        var allTables = [...document.getElementsByTagName('table')];

        changeTarget(allLinks);
        changeTable(allTables);

    });

    return (
        <div className="content-wrapper">
            {parse(content)}
        </div>
    )
}

export default ContentWrapper;
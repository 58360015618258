import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import ContentWrapper from './components/ContentWrapper';
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
  Navigate
} from "react-router-dom";

var url = "https://datafcb.com";

var request = fetch(`${url}/article/getArticles/`)
.then((res) => res.json())

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route
      path="/"
      element={<App url={url}/>}
      loader={async ({ params }) => {
        return {request: request, id: params.id};
      }}
    >
      <Route
        path=":id"
        loader={async ({ params }) => {

          return {request: request, id: params.id};
        }}
        element={<ContentWrapper url={url} />}
      />
    </Route>
  )
);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);
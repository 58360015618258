import './App.css';
import { useEffect, useRef, useState } from 'react';
import Header from './components/Header';
import ContentWrapper from './components/ContentWrapper';
import { Outlet, useNavigate, useLoaderData, useMatch } from 'react-router-dom';

function App({ url, ...props }) {

  var loader = useLoaderData();
  var match = useMatch("/");

  const navigate = useNavigate();
  const [boletins, setBoletins] = useState([]);

  useEffect(() => {
    loader.request
      .then((data) =>
        setBoletins(data)
      )
  }, [])

  useEffect(() => {
    if (match !== null) {
      navigate(`/${boletins.length}`)
    } else {
    }
  }, [boletins])

  return (
    <div className="App">

      <Header />
      <select name="boletim" id="boletins" onChange={(e) => { navigate(`/${e.target.value}`) }}>
        <option value={null}>Escolha um Boletim</option>

        {boletins.map((boletim, i) => {
          return <option key={i} value={boletins.indexOf(boletim)}>{boletim.fields.title}</option>
        })}
      </select>

      <Outlet />

    </div>
  );
}

export default App;
